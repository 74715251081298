/* .how-it-works-wrapper {
  background-color: #242020;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
  width: 100%;
  height: 500px;
}

.how_it_works {
}
.how-it-works-1 {
  padding-top: 20px;
}
.how-it-works-rectangle {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  margin-top: -27%;
}

.how-it-works-rectangle img {
  width: 100%;
  height: auto;
} */
.how-it-works-wrapper {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
  width: 100%;
  /* padding: 40px 0; */
  /* Adjusted to better fit various screen sizes */
}

.how-it-works-wrapper::before {
  width: 100%;
  left: 0;
  background-color: #242020;
  content: "";
  position: absolute;
  top: 0;
  height: 60%;
}

.how_it_works {
  max-width: 1122px;
  margin: 0 auto;
  /* margin-bottom: 20px; */
  position: relative;
}

.how-it-works-1 {
  /* padding-top: 20px; */
  font-size: 32px; /* Adjusted font size for better readability on smaller screens */
}

.how-it-works-rectangle {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.how-it-works-rectangle video {
  width: 100%;
  height: auto;
  border-width: 10px;
  border-radius: 41px;
  border-color: #355935;
  border-style: solid;
}

@media (max-width: 867px) {
  .how-it-works-1 {
    /* padding-top: 10px; */
    font-size: 1.5rem; /* Adjusted font size for better readability on smaller screens */
  }

  .how-it-works-wrapper {
    /* padding: 20px 0; */
    /* Adjusted to better fit various screen sizes */
  }
}

@media (max-width: 560px) {
  .how-it-works-1 {
    padding-top: 0px;
    font-size: 1.2rem; /* Adjusted font size for better readability on smaller screens */
  }

  .how-it-works-wrapper {
    padding: 20px 0;
    /* Adjusted to better fit various screen sizes */
  }
}

@media (max-width: 380px) {
  .how-it-works-1 {
    padding-top: 0px;
    font-size: 1rem; /* Adjusted font size for better readability on smaller screens */
  }

  .how-it-works-wrapper {
    padding: 20px 0;
    /* Adjusted to better fit various screen sizes */
  }
}

/* Responsive Design */

/* Tablets and larger mobile devices (768px and below) */
