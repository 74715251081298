.first-slider .slick-slide {
  margin: 0 10px; /* Adjust this value to set the gap between slides */
}

.first-slider .slick-track {
  display: flex; /* Ensure the slides are aligned horizontally */
  /* width: 600px; */
}

.Working_h1 {
  font-size: 45px;
  font-weight: bold;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.Img_1 {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.Img_1 img {
  max-width: 800px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 8px 11px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.arrow:hover {
  background-color: #f37b2f;
}

.button_1 {
  background-color: #ffffff;
  align-items: center;
  border-radius: 20px;
  color: #F37B2F;
  cursor: pointer;
  padding: 14px 14px;
  border: 4px solid #F37B2F;
  width: 400px; /* Set a fixed width */
  height: 100px; /* Set a fixed height */
  text-align: center; /* Center the text */
  line-height: 24px; /* Adjust the line height for better text alignment */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing content */
  /* Add ellipsis for overflowing text */
  font-size: 16px; /* Adjust font size to fit content */
}

.button_1.active {
  color: #F37B2F; /* Orange */
  font-weight: bold;
  border: 4px solid #F37B2F;
}

/* Inactive buttons (Grey text) */
.button_1.inactive {
  color: #242020; /* Grey color */
  border: 4px solid #242020;
}

.first-slider {
  position: relative;
  overflow: hidden; /* Hide overflow on the sides */
  width: 100%;
}

.first-slider .slick-slide {
  flex: 0 0 auto;
  width: 220px; /* Adjust to control the size of each slide */
  margin: 0 10px;
  opacity: 0.5; /* Reduce opacity for non-selected items */
  transition: opacity 0.3s ease;
}

.first-slider .slick-slide.slick-center {
  opacity: 1;
}

.button_1 {
  background-color: #ffffff;
  align-items: center;
  border-radius: 20px;
  color: #f37b2f;
  cursor: pointer;
  padding: 14px 20px;
  border: 2px solid #f37b2f;
  width: 100%; /* Ensure full width within slide */

  text-align: center;
  line-height: 1.4em; /* Adjust for better line spacing */
  white-space: normal; /* Allow text to wrap naturally */
  overflow: hidden;
  font-size: 16px;
}

@media (max-width: 650px) {
  .button_1 {
    padding: 11px 11px;
    border: 2px solid #f37b2f;
    width: 150px; /* Set a fixed width */
    height: 80px; /* Set a fixed height */

    line-height: 20px; /* Adjust the line height for better text alignment */

    font-size: 12px; /* Adjust font size to fit content */
  }
}
@media (max-width: 400px) {
  .button_1 {
    padding: 6px 6px;
    border: 2px solid #f37b2f;
    width: 150px; /* Set a fixed width */
    height: 80px; /* Set a fixed height */

    line-height: 16px; /* Adjust the line height for better text alignment */

    font-size: 11px; /* Adjust font size to fit content */
  }
  
}

@media (max-width: 390px) { 
  .Working_h1 {
    font-size: 1.2rem;
  }
}

