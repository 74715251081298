/* .download-section {
  padding: 60px 10%;
  background-color: #ffffff;
  text-align: center;
  color: #000000;
}

.download-section h2 {
  font-size: 2.2rem;
  margin-bottom: 30px;
}

.download-buttons img {
  margin-right: 15px;
  max-height: 50px;
}

.hero-buttons_1 {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.hero-apple-button_1 {
  background-color: #000000;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.hero-apple-button_1 img {
  width: 20px;
  margin-right: 10px;
}

.button-text_1 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.2;
}

.hero-play-button_1 {
  background-color: #000000;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  border: none;
}

.hero-play-button_1 img {
  width: 20px;
  margin-right: 10px;
}

.button-text_1 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.2;
} */

.download-section {
  padding: 60px 10%;
  background-color: #ffffff;
  text-align: center;
  color: #000000;
}

.download-section h2 {
  font-size: 50px;
  margin-bottom: 30px;
}

.download-section img {
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
}

.hero-buttons_1 {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.hero-buttons_1 img {
  max-width: 580px;
}

.Download-play {
  max-width: 200px;
  cursor: "pointer";
}

.ContactUsText {
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  padding-bottom: 30px;
}

.ContactUsTextEmailText {
  font-size: 20px;
}
.attherate {
  width: 61px;
  height: 61px;
}
.ContactUsTextEmail {
  color: #f37b2f;
  font-size: 24px;
  font-weight: 600;
}
/* Responsive Design */

/* Tablets and larger mobile devices (768px and below) */
@media (max-width: 768px) {
  .download-section {
    padding: 40px 5%;
  }

  .download-section h2 {
    font-size: 1.8rem;
  }
  .hero-content h2 {
    font-size: 40px;
  }

  .hero-buttons_1 {
    padding: 8px 16px;
  }
}

/* Small mobile devices (480px and below) */
@media (max-width: 480px) {
  .download-section {
    padding: 30px 5%;
  }

  .attherate {
    width: 41px;
    height: 41px;
  }

  .ContactUsTextEmail {
    color: #f37b2f;
    font-size: 16px;
    font-weight: 600;
  }
  .ContactUsTextEmailText {
    font-size: 14px;
  }
  .download-section h2 {
    font-size: 1.6rem;
  }

  .hero-content h2 {
    font-size: 30px;
  }

  .download-section img {
    max-width: 120px;
    margin-bottom: 15px;
  }

  .hero-buttons_1 {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .hero-buttons_1 {
    padding: 10px 20px;
    height: 50px;
  }
}

/* Very small mobile devices (320px and below) */
@media (max-width: 320px) {
  .download-section {
    padding: 20px 5%;
  }

  .download-section h2 {
    font-size: 1.4rem;
  }

  .download-section img {
    max-width: 100px;
    margin-bottom: 10px;
  }

  .hero-apple-button_1,
  .hero-play-button_1 {
    padding: 8px 15px;
  }

  .button-text_1 {
    font-size: 9px;
  }
}
