@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

// Override Bootstrap's default font family
$font-family-base: 'Poppins', sans-serif;

@import "bootstrap/scss/functions";

@import "scss/custom-variables";

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

@import "scss/custom-mixins";
@import "scss/typography";
@import "scss/button";
@import "scss/loader";
@import "scss/switch";
@import "scss/header";
@import "scss/footer";
@import "scss/layout";
@import "scss/sidebar";
@import "scss/form";
@import "scss/checkbox";
@import "scss/modal";
@import "scss/login";
@import "scss/game";
@import "scss/common";
@import "scss/icons";
@import "scss/table";
@import "scss/tab";


body {
    font-family: 'Poppins', sans-serif;
  }

.pageHeading{
  font-size: 26px;
}

@media (max-width: 768px){
  .pageHeading{
    font-size: 18px;
  }
}
