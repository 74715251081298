.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-height: 10.4rem;
  margin-left: 24rem;
  transition: margin-left 0.3s ease-in-out;


  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }

  .burger-menu {
    font-size: 2.4rem;
    cursor: pointer;
  }

  nav {
    padding: 0 1.7rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #355935;
    color: white;

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }

    .navbar-nav {
      .nav-item {
        @extend .d-flex, .align-items-center;
      }

      .nav-item:not(:last-child) {
        margin-right: 1rem;
      }

      .nav-link {
        border-radius: 0;
        color: white;
        font-weight: 500;
        padding: 0.4rem;

        .icon-settings {
          font-size: 2rem;
        }

        .profile-pic {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          overflow: hidden;
          border: 0.2rem solid rgba(222, 227, 234, 1);

          img {
            width: 100%;
            height: 100%;
            min-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.user-profile {
  flex-direction: column;

  p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    color: #111927;
  }

  span {
    font-weight: 400;
    // color: #6c737f;
    font-size: 14px !important;
  }
}