.main-wrapper {
  min-height: calc(100vh - 6.2rem);
  background-color: #355935;
}

.content-wrapper {
  margin-left: 24rem;
  padding: 5.7rem 3.7rem 1.7rem 3.7rem;
  min-height: calc(100vh - 6.2rem);
  transition: all 0.3s ease-in-out;
  margin-top: 10.4rem;
  border-radius: 2.4rem;
  background: #fff;

  .conent-header {
    margin-bottom: 1.6rem;
    @extend .d-flex, .justify-content-between, .align-items-center;

    @media (max-width: 380px) {
      flex-wrap: wrap;
    }

    >.row {
      @extend .flex-fill;
    }

    .breadcrumb {
      @extend .mb-0;
    }
  }
}

// Temp css
.tooltip {
  position: fixed;
}


@media (max-width: 768px) {
  .content-wrapper {
    margin-left: 0rem;

    margin: 10.4rem 1rem 0rem 1rem;

  }
}