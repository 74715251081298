
.createNewPassContainer {
    width: 100%;
    height: 80vh;
    background-color: #130731;
    overflow-y: auto;
    padding: 20px;
    gap: 30px;
    border-radius: 8px;
  }
    
  .createNewPassInputCon{
    width: 50%;
  }
  
  .changeNewPassText{
  /* font-family: Montserrat; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
  opacity: 0.6;
  }
  
  .createNewPassButton{
    width: 50%;
    min-width: 250px;
  }
  .editPassCancel{
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  cursor: pointer;
  }
    .valid{
      color:#BA6DA7;
      display: flex;
      gap:5px;
      /* justify-content: center; */
      align-items: center;
      /* font-family: Roboto; */
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  
    }
    
    .invalid{
      color: #fff;
      display: flex;
      gap:5px;
      align-items: center;
      /* font-family: Roboto; */
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
    }
    
  .passwordCriteria{
      font-size: 15px;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      gap: 3px;
  }  
  
  @media (max-width: 768px) {
    .createNewPassContainer{
      width: 100%;
      height: 100vh;
    }
    .createNewPassInputCon{
      width: 100%;
    }
    .createNewPassButton{
      width: 90%;
    }
  }