/* .about-section-wrapper {
  background-color: #242020;
}
.about-section {
  color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 40px 20px;
  align-items: center;
}

.image-section img {
  max-width: 400px;
  width: 100%;
}

.content-section {
  max-width: 500px;
  margin-left: 20px;
}

.content-section h2 {
  font-size: 24px;
  color: white;
  margin-bottom: 10px;
  line-height: 0.5;
  font-weight: 300;
}

.content-section h3 {
  font-size: 24px;
  color: #ff6f00;
  margin-bottom: 10px;
  line-height: 0.5;
}

.content-section p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  font-weight: 400;
}

.testimonial {
  display: flex;
  align-items: center;
}

.testimonial img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.testimonial-content {
  font-size: 18px;
  font-weight: bold;
}

.testimonial-content .role {
  font-size: 14px;
  color: #ccc;
} */

.about-section-wrapper {
  background-color: #242020;
  padding: 40px 0;
}

.about-section {
  color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 40px 20px;
  align-items: center;
  flex-wrap: wrap;
}

.image-section img {
  max-width: 400px;
  width: 100%;
  height: auto;
}

.content-section {
  max-width: 500px;
  margin-left: 20px;
  text-align: left;
}

.content-section h2 {
  font-size: 45px;
  color: white;
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 300;
}

.content-section h3 {
  font-size: 45px;
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.2;
}

.content-section h5 {
  font-size: 20px;
  color: #F37B2F;
  margin-bottom: 10px;
  line-height: 1.2;
}

.content-section p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  font-weight: 400;
}

.testimonial {
  display: flex;
  align-items: center;
}

.testimonial img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.testimonial-content {
  font-size: 18px;
  font-weight: bold;
}

.testimonial-content .role {
  font-size: 14px;
  color: #ccc;
}

/* Responsive Design */

/* Tablets and larger mobile devices (768px and below) */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    text-align: center;
  }

  .content-section {
    max-width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .content-section h2,
  .content-section h3 {
    font-size: 22px;
  }

  .content-section p {
    font-size: 15px;
    line-height: 26px;
  }

  .testimonial {
    justify-content: center;
  }
}

/* Small mobile devices (480px and below) */
@media (max-width: 480px) {
  .about-section {
    padding: 20px 20px;
  }

  .image-section img {
    max-width: 100%;
  }

  .content-section h2,
  .content-section h3 {
    font-size: 20px;
  }

  .content-section p {
    font-size: 14px;
    line-height: 24px;
  }

  .testimonial-content {
    font-size: 16px;
  }
}

/* Very small mobile devices (320px and below) */
@media (max-width: 320px) {
  .about-section {
    padding: 15px 20px;
  }

  .content-section h2,
  .content-section h3 {
    font-size: 18px;
  }

  .content-section p {
    font-size: 13px;
    line-height: 22px;
  }

  .testimonial-content {
    font-size: 14px;
  }
}
