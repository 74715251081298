.tab-container {
  .nav {
    border: none;
    flex-wrap: nowrap;

    >.nav-item {
      max-width: 25rem;
      width: 100%;

      .nav-link {
        width: 100%;
        font-size: 1.8rem;
        text-align: center;
        border: none;
        background-color: $white;
        border-radius: 0;

        @include media-breakpoint-down(sm) {
          font-size: 1.6rem;
          padding: .8rem;
        }

        &.active {
          color: $white;
          background: linear-gradient(to bottom, #fba79d, #f99b90, #f78e83, #f48176, #f17469);
        }
      }

      &:last-child {
        .nav-link {
          border-radius: 0 .8rem .8rem 0;
        }
      }

      &:first-child {
        .nav-link {
          border-radius: .8rem 0 0 .8rem;
        }
      }
    }

    &.tabs-dark {
      .nav-item {
        .nav-link {
          background-color: $light-gray-100;
        }
      }
    }
  }
}