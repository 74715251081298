.login-layout {
  display: flex;
  /* height: 100vh; */
  overflow: visible; /* Ensure no clipping occurs */
}
/* .video-container {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden; 
}

.my-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  object-fit: contain; 
  width: 100%; 
  height: auto;
}

.my-video::-webkit-media-controls {
  transform: scale(0.8);
}

.my-video::-webkit-media-controls-volume-slider,
.my-video::-webkit-media-controls-play-button,
.my-video::-webkit-media-controls-mute-button,
.my-video::-webkit-media-controls-fullscreen-button {
  transform: scale(0.8);
}

@media (max-width: 768px) {
  .video-container {
    height: 25vh; 
    overflow: visible; 
  }

  .my-video {
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%) scale(1.1);
  }
  .my-video::-webkit-media-controls {
    transform: scale(1);
  }

  .my-video::-webkit-media-controls-volume-slider,
  .my-video::-webkit-media-controls-play-button,
  .my-video::-webkit-media-controls-mute-button,
  .my-video::-webkit-media-controls-fullscreen-button {
    transform: scale(1);
  }
}
*/
.login-left {
  flex: 6; /* Takes 60% of the available width */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Ensures proper stacking of content */
  padding: 5px;
  min-height: 0; /* Fix flexbox height issues */
  height: 100%; /* Allow full height allocation */
  overflow-y: auto; /* Enable scrolling for overflow content */
}

.gif {
  width: 200px;
}

.login-right {
  flex: 4; /* Takes 40% of the available width */
  background-color: white;
  border-top-left-radius: 20px; /* Round top left corner */
  border-bottom-left-radius: 20px;
  /* display: flex;  */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 5px;
  overflow-y: auto;
  border-left: 1px solid;
}

.login-circle {
  width: 100px; /* Set width */
  height: 100px; /* Set height */
  border: 2px solid #355935;
  background-color: white; /* Set background color to white */
  color: antiquewhite; /* Text color */
  border-radius: 50%; /* Make it circular */
  display: flex; /* Use flexbox for centering content */
  justify-content: center; /* Center content horizontally */
  align-items: center;
  position: relative;
}

.login-circle-container {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-icon {
  position: absolute;
  width: 70px;
  height: 70px;
}

.benefit {
  font-weight: 700;
  font-size: 37.15px;
}

.benefitText {
  font-weight: 400;
  font-size: 14px;
}

.value-cards-layout {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.value-cards-layout li {
  font-size: 12px;
}

.value-card-layout {
  border: 3px solid #ddd;
  border-radius: 34px;
  padding: 30px;
  max-width: 350px;
  text-align: left;
}

.value-card-1-layout {
  border-color: #f37b2f; /* Orange color */
}

.value-card-2-layout {
  border-color: #355935; /* Green color */
}

@media (max-width: 768px) {
  .login-layout {
    flex-direction: column;
    height: auto; /* Allow layout height to adjust for content */
  }

  .login-right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    flex: unset;
    border-left: unset;
    border-top: 1px solid;
    height: auto; /* Let it expand based on content */
    min-height: 55%; /* Ensure enough height for visibility */
    overflow-y: auto; /* Enable scrolling if content overflows */
    padding: 20px; /* Add consistent padding */
  }

  .login-left {
    flex: unset;
    height: auto; /* Adjust height */
    padding: 5px;
    min-height: 300px; /* Ensure enough space for content */
  }
}
