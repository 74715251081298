.auth-wrapper {
  position: relative;
  // background-color: #F9FAFB;
  min-height: 100vh;

  .auth-box {
    // max-width: 60%;
    // margin: 23% auto 0;
    padding: 40px;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    // align-items: center;

    .title {
      color: $black;
      font-weight: 700;
      font-size: 3.2rem;
      margin-bottom: 3.5rem;
      line-height: 4.5rem;
    }

    .info-text {
      text-align: center;
      font-size: 1.2rem;

      a {
        color: $black;
      }
    }
  }
}

.auth-left {
  min-height: 100vh;
}

.auth-image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #f9fafb;
  background: linear-gradient(89.84deg, #661eff 0.13%, #ba6da7 117.75%);
}
.login-box {
  background: #fff;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
.auth-inner {
  padding: 40px;
  max-width: 500px;
  width: 100%;
}

.login-data {
  width: 100%;
  flex: 0 0 auto;
}
