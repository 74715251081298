/* 
.footer-wrapper {
  background-color: #242020;
}
.footer {
  color: #fff;
  width: 100%;
}

.footer nav {
  padding: 30px 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.left-footer {
  display: flex;
  justify-content: space-around;

  flex: 1;
}

.right-footer {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  padding-right: 40px;
}

.footer nav a {
  text-decoration: none;
  color: white;
}

.footer p {
  margin: 0;
  font-size: 1rem;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  p {
    opacity: 30%;
  }
} */

.footer-wrapper {
  background-color: #242020;
  color: white;
}

.footer {
  width: 100%;
}

.footer nav {
  padding: 30px 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.left-footer {
  display: flex;
  justify-content: space-around;
  flex: 1;
  flex-wrap: wrap;
}

.right-footer {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  flex: 1;
  flex-wrap: wrap;
  /* padding-right: 40px; */
  text-align: right;
}

.footer nav a {
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
  font-size: 1rem;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright p {
  opacity: 30%;
  font-size: 0.9rem;
}

/* Responsive Design */

/* Tablets and larger mobile devices (768px and below) */
@media (max-width: 768px) {
  .footer nav {
    padding: 20px 20px;
    flex-direction: column;
    text-align: center;
  }

  .left-footer,
  .right-footer {
    justify-content: space-around;
    padding-right: 0;
  }

  .right-footer {
    margin-top: 10px;
  }

  .footer nav a {
    margin: 10px 0;
  }
}

/* Small mobile devices (480px and below) */
@media (max-width: 480px) {
  .footer nav {
    padding: 15px 15px;
  }

  .left-footer {
    justify-content: space-around;
    padding-right: 0;
    flex-direction: column;
  }

  .right-footer{
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }

  .footer nav a {
    font-size: 0.9rem;
  }

  .right-footer a {
    font-size: 0.9rem;
  }

  .copyright p {
    font-size: 0.8rem;
  }
}

/* Very small mobile devices (320px and below) */
@media (max-width: 320px) {
  .footer nav {
    padding: 10px 10px;
  }

  .footer nav a {
    font-size: 0.8rem;
  }

  .right-footer a {
    font-size: 0.8rem;
  }

  .copyright p {
    font-size: 0.7rem;
  }
}

@media (max-width: 390px) {
  .footer nav {
    padding: 10px 10px;
  }

  .footer nav a {
    font-size: 0.8rem;
  }

  .right-footer a {
    font-size: 0.8rem;
  }

  .copyright p {
    font-size: 0.7rem;
  }

  .right-footer{
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }
}
