.modal {

  .btn-close-modal,
  .btn-close-modal:active {
    background-color: rgba($black, .12);
    border-radius: 50%;
    width: 2.8rem;
    height: 2.8rem;
    padding: 0;
    font-size: 1.8rem;
    border: none;
    color: rgba($black, .8);
  }

  .modal-content {
    border: none;

    .modal-title-h1 {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
}