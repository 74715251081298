.hero-section-wrapper {
  position: relative;
  overflow: hidden;
}
.Download-play-hero {
  max-width: 150px;
  cursor: "pointer";
}
.hero-small-button {
  display: none;
}
.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding: 0px 0px 20px 0px;
}

.hero-content {
  flex: 0 0 auto;
  width: 74%;
  z-index: 2; /* Ensures content stays on top of background image */
  /* padding: 0px 0px 0px 50px; */
}

.hh {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin: 0px;
}

.hh1 {
  font-weight: 200;
  margin-right: 5px;
}

.hero-attend {
  color: #355935;
  font-weight: 600;
  /* margin: 20px 0; */
  font-size: 22px;
  margin: 0px;
}

.hero-virtual {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 14px;
}

.hero-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.hero-buttons img {
  width: 370px;
  height: 54px;
}

.hero-button {
  display: flex;
  gap: 5px;
  cursor: pointer;
  max-width: 350px;
}

.hero-download {
  color: #f37b2f;
  font-weight: bold;
  margin-top: 10px;
}
.hero-image-outer {
  position: relative;
  width: 24%;
  flex: 0 0 auto;
}
.hero-image-thumb {
  position: absolute;
  width: 135%;
  top: 50%;
  transform: translate(-7%, -53%);
  /* transform: translateY(-50%); */
  /* left: 50%; */
  right: -15%;
}

.hero-image-thumb img {
  position: absolute;
  top: 9px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-image-thumb::before {
  display: block;
  content: "";
  padding-top: 80%;
}

@media (max-width: 1200px) {
  .hh {
    font-weight: 700;
    font-size: 30px;
  }

  .hh1 {
    font-weight: 200;
    margin-right: 5px;
  }

  .hero-attend {
    color: #355935;
    font-weight: 600;
    /* margin: 20px 0; */
    font-size: 14px;
  }

  .hero-virtual {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 12px;
  }
  .hero-image-thumb {
    transform: translateY(-50%);
    width: 130%;
    right: 0;
  }
  .hero-section {
    padding: 0px 0px 20px 0px;
  }
}

@media (max-width: 1200px) {
  .hh {
    font-weight: 700;
    font-size: 1.6rem;
  }

  .hh1 {
    font-weight: 200;
    margin-right: 5px;
  }

  .hero-attend {
    color: #355935;
    font-weight: 600;
    /* margin: 20px 0; */
    font-size: 12px;
  }

  .hero-virtual {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 12px;
  }
  .hero-image-thumb {
    width: 130%;
    right: 0;
  }
  .hero-section {
    padding: 0px 0px 40px 0px;
  }

  .hero-content {
    width: 74%;
    /* padding: 0px 0px 0px 50px; */
  }
  .hero-image-outer {
    width: 24%;
  }
}

@media (max-width: 767px) {
  .hh {
    font-weight: 700;
    font-size: 1.6rem;
  }

  .Download-play-hero {
    max-width: 150px;
    cursor: "pointer";
  }

  .hh1 {
    font-weight: 200;
    margin-right: 5px;
  }

  .hero-attend {
    color: #355935;
    font-weight: 600;
    /* margin: 20px 0; */
    font-size: 12px;
  }

  .hero-virtual {
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 12px;
  }
  .hero-image-thumb {
    width: 100%;
    position: relative;
    transform: none;
    top: auto;
  }
  .hero-section {
    padding: 0px 0px 40px 0px;
  }

  .hero-content {
    /* padding: 0px 20px; */
    width: 100%;
  }
  .hero-image-outer {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }
  .hero-small-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  .hero-button {
    display: none;
  }
}

@media (max-width: 490px) {
  .hero-buttons img {
    width: 300px;
    height: 40px;
  }
  .hh {
    font-weight: 700;
    font-size: 1.4rem;
  }

  .hh1 {
    font-weight: 200;
    margin-right: 5px;
  }

  .hero-download {
    color: #f37b2f;
    font-weight: bold;
    margin-top: 10px;
    font-size: 10px;
  }

  .hero-attend {
    color: #355935;
    font-weight: 600;
    /* margin: 20px 0; */
    font-size: 12px;
  }

  .hero-virtual {
    margin-bottom: 5px;
    line-height: 1.3;
    font-size: 10px;
  }
  .hero-image-thumb {
    width: 100%;
    position: relative;
    transform: none;
    top: -20px;
  }
  .hero-section {
    padding: 0px 0px 0px 0px;
  }

  .hero-content {
    /* padding: 0px 20px; */
    width: 100%;
  }
  .hero-image-outer {
    max-width: 500px;
    margin: 0 auto;
    width: 60%;
  }
  .hero-image-thumb img {
    position: absolute;
    top: 30px;
    left: 0;
    width: 80%;
    height: 80%;
    object-fit: cover;
  }
}

/* Media Query for devices with max-width of 480px */
