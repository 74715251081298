img {
  max-width: 100%;
}

.card {
  background-color: $white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.06);
  position: relative;

  .card-filter {
    padding-bottom: 0.4rem;
  }

  .card-body {
    padding: 1.5rem;
  }
}

.dashboard-card {
  height: 100%;

  .card-body {
    padding: 2.4rem;
  }

  .dashboard-icon {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    color: $primary;
    border: 2px solid rgba($primary, 0.32);
    background-color: rgba($primary, 0.12);
    margin: 0 auto 1.2rem;
    font-size: 2rem;
    @extend .d-flex, .align-items-center, .justify-content-center;
  }

  h2 {
    font-size: 2.4rem;
    color: $primary;
    @extend .text-center, .fw-bold;
  }

  dl {
    font-size: 1.8rem;
    @extend .d-flex, .justify-content-between;

    dd {
      @extend .mb-0;
    }
  }
}

.scroll-content {
  height: 60vh;
  min-height: 300px;
  overflow-y: auto;
}

.user-list {
  @extend .list-unstyled, .mb-0;

  li {
    margin-bottom: 1.2rem;
    @extend .d-flex, .align-items-center;

    .user-left-col {
      width: 6rem;
      margin-right: 2rem;

      .user-thumb {
        border-radius: 50%;
        border: solid 1px $gray-200;
        @include img-box(100%);
      }
    }

    .user-right-col {
      flex: 1;

      p {
        margin: 0;
      }
    }
  }
}

.charity-scroller {
  max-height: 65vh;
  overflow-y: auto;
}

.comment-scroller {
  max-height: 80vh;
  overflow-y: auto;
}

.charity-list {
  @extend .list-unstyled, .mb-0;

  >li {
    @extend .d-flex, .align-items-start, .justify-content-between;

    h5 {
      font-size: 2rem;
    }

    p {
      margin-bottom: 0.4rem;

      strong {
        margin-right: 0.4rem;
      }
    }

    .btn {
      margin-left: 0.8rem;
      @extend .flex-shrink-0;
    }

    +li {
      padding-top: 1.6rem;
      margin-top: 1.6rem;
      border-top: solid 1px $gray-200;
    }
  }
}

.comment-inline-row {
  flex: 1;
  @extend .d-flex, .gap-3;

  .comment-col-left {
    width: 6rem;

    .user-c-thumb {
      border-radius: 50%;
      border: solid 1px $gray-200;
      @include img-box(100%);
    }
  }

  .comment-col-right {
    flex: 1;
  }
}

.custom-dropdown {
  .dropdown-item {

    &.active,
    &:hover,
    &:active {
      background-color: transparent;
      color: $primary;
    }
  }
}

.swal2-container {
  .swal2-modal {
    padding-bottom: 2em;
  }

  .swal2-popup {
    font-size: 1.5rem;
    color: $body-color;

    .swal2-icon {
      width: 5em;
      height: 5em;
    }

    .swal2-content {
      color: $body-color;
      font-size: 1.5rem;
    }

    .swal2-title {
      color: $body-color;
      font-size: 2rem;
    }

    .swal2-styled {
      min-width: 0.8rem;
      font-size: 1.5rem;
      border-radius: 0.8rem;
      border: none;
      min-width: 9.8rem;

      &.swal2-confirm {
        font-size: 1.5rem;
        color: $white;
        background: linear-gradient(to right,
            #fba79d,
            #f99b90,
            #f78e83,
            #f48176,
            #f17469);
        font-weight: 500;

        &:hover {
          font-size: 1.5rem;
          color: $white;
          background: linear-gradient(to right,
              #fba79d,
              #f99b90,
              #f78e83,
              #f48176,
              #f17469);
          font-weight: 500;
        }
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

/* Works on Firefox */
* {
  // scrollbar-width: thin;
  // scrollbar-color: $gray-400 $light-gray-100;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background-color: $light-gray-100;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  border-radius: 20px;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.event-list {
  display: flex;
  list-style-type: none;
  gap: 2rem;
}

.event-date {
  font-weight: 700;
  font-size: 1.2rem;
  color: rgb(7 24 29 / 55%);
}

.event-list {
  flex-wrap: wrap;
}

.event-list li {
  position: relative;
  background-color: white;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  padding: 0.6rem 1rem;
  border-radius: 0.8rem;
}

.event-list li a {
  text-decoration: none;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.2;
}

.change-password-wrapper .login-box {
  background-color: rgba(240, 244, 244, 0.5);
  border: 0px solid;
  border-radius: 2rem;
  min-height: calc(100vh - 10rem);
}

.cursor-pointer {
  cursor: pointer;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: show;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}


.shine {
  background: #f6f7f8;
  background-image: linear-gradient(30deg,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

lines {
  height: 10px;
  margin-top: 10px;
  width: 200px;
}

.shimmer {
  display: inline-flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.resend-link {
  color: blue;
  cursor: pointer;


}

.resend-link-disabled {
  color: grey;
  pointer-events: none;
}

.page-title-gray {
  opacity: 0.5;
}

.redirection {
  text-decoration: underline;
  color: blue !important;
  cursor: pointer;
}