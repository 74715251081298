@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* Base styles */
html,
body {
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
}

/* Font size adjustments for responsive design */
:root {
  @include media-breakpoint-down(xxl) {
    font-size: 58.5%;
  }

  @include media-breakpoint-down(lg) {
    font-size: 55%;
  }
}

/* Selection styling */
::selection {
  background-color: rgba($primary, 0.2);
  color: $body-color;
}

/* Typography */
p {
  line-height: 1.5;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.8rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2rem;
  line-height: 1.2;
}

h6 {
  font-size: 1.6rem;
}

/* Utility classes */
.fs-15 {
  font-size: 1.5rem !important;
}

.fs-16 {
  font-size: 1.6rem !important;
}

.fw-medium {
  font-weight: 500 !important;
}

/* Custom page elements */
.page-title {
  margin-bottom: 0;
  font-weight: 600;
  color: #111927;
  font-size: 20px;
  opacity: 0.5;
}

.games-details-heading {
  font-weight: 600;
  color: #111927;
  font-size: 16px;
}

.games-details-free-btn {
  display: flex;
  justify-content: end;
  border-radius: 1.2rem;

  span {
    color: #fff;
  }
}
