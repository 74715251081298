@mixin alignment-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}
.game-items {
  .title {
    color: $black;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    opacity: 50%;
    @media screen and (max-width: 500px) {
      font-size: 1.2rem;
    }
    @media screen and (min-width: 500px) and (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  .games {
    display: flex;
    flex-wrap: wrap;

    .game {
      margin-bottom: 3rem;
      position: relative;
      color: $black;
      font-size: 1.8rem;
      font-weight: 700;
      box-shadow: 0px 5px 20px 5px #0000000d;
      background-color: $white;
      border-radius: 1rem;
      margin-left: 1.8rem;
      padding: 0.7rem 0.9rem;
      transition: 0.3s all;
      @media screen and (max-width: 800px) {
        font-size: 1.4rem;
      }

      @include alignment-center;

      &:hover {
        box-shadow: none;
      }

      .game-badge {
        color: $white;
        font-weight: normal;
        border-radius: 10rem;
        line-height: 1;
        padding: 0.4rem 1rem;
        font-size: 1rem;
        position: absolute;
        right: 0;
        top: -50%;
        transform: translateY(50%);
        @include alignment-center;

        &.free {
          background-color: $brown;
        }

        &.purchased {
          background-color: $green;
        }
      }
    }
  }
}

.game-header {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 3.3rem;

  .section-one {
    .btn1 {
      border: 2px solid $green;
      height: 4.6rem;
      width: 21.6rem;
      border-radius: 3rem;
      color: $green;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .section-two {
    button {
      background-color: $black;
      color: $white;
      height: 5rem;
      width: 18.1rem;
      border-radius: 1.2rem;
    }
    .btn2 {
      margin-right: 1.2rem;
    }
  }
}

.game-details-card {
  background-color: #1d1d1d;
  border-radius: 2.4rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;

  .title {
    color: white;
  }

  .info-wrapper {
    .info-icon {
      position: relative;
      background-color: $white;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  // .info-wrapper.active {
  //   &::after {
  //     content: "";
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background: #74666630;
  //     z-index: 2;
  //     backdrop-filter: blur(15px) brightness(30%);
  //   }
  //   .info-icon {
  //     z-index: 3;
  //   }
  // }

  .left-candidate {
    color: white;
    margin-top: 0rem;
  }
  .left-candidate-second {
    background-color: rgba(#ffffff, 0.2);
    border-radius: 2rem;
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    width: 50px;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .left-candidate-second span {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 2px solid $green;

    &.filled {
      background-color: $green;
    }
  }
  .center-image {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .center-image::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 10px);
    height: 1px;
    background-color: rgba($white, 0.1);
  }
  .center-candidate {
    color: $black;
    background-color: $white;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-weight: 600;
  }
  .right-candidate {
    color: white;
    margin-top: 0rem;
  }
}

// .bd{
//   span{
//     &:nth-child(1){

//     }
//     &
//   }
// }

// Define a list of colors
$colors: #4f7ba8, #508ba9, #6aa9a2, #c1dda9, #ebf0b6, #d2d5b3, #e6ac73, #bd505d,
  #a73754, #9d2c4f, #a73754, #bd505d, #e6ac73, #ebc588, #d2d5b3, #97c8a7,
  #6aa9a2, #508ba9, #507ba8;

// Define the number of spans
$span-count: length($colors);

.colorful-distribution {
  margin-top: 2rem;
  display: flex;
  gap: 1px;
  @for $i from 1 through $span-count {
    span:nth-child(#{$i}) {
      background-color: nth($colors, $i);
      width: calc(100% / 19);
      height: 1.7rem;
    }
  }
}

.colorful-distribution-numbers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    color: $white;
  }
}

.percentage-distribution {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba($white, 0.1);
  padding-top: 1rem;
}

.card-title {
  font-size: 2rem;
  font-weight: 700;
}

.empty-card {
  background-color: $white;
  border-radius: 2rem;
  box-shadow: 0px 5px 20px 5px #0000000d;
  padding: 9rem 0rem;
  margin-bottom: 3rem;
  text-align: center;

  @media screen and (max-width: 500px) {
    img {
      width: 20rem;
    }
    padding: 5rem 0rem;
  }
}

.first-card,
.second-card {
  background-color: $white;
  border-radius: 2rem;
  box-shadow: 0px 5px 20px 5px #0000000d;
  padding: 3rem 1rem;

  padding-bottom: 14.5rem;
  @media screen and (max-width: 480px) {
    padding-bottom: 2rem;
  }
  margin-top: 1rem;
  margin-bottom: 5rem;

  position: relative;

  .first-card-info-icon {
    .info-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
  }

  .grey-wrapper {
    display: flex;
    gap: 1rem;

    @media screen and (max-width: 480px) {
      gap: 0.5rem;
    }

    .left-most {
      width: 38px;
      @media screen and (max-width: 480px) {
        width: 27px;
      }
    }

    .right-most {
      width: 78px;
      @media screen and (max-width: 480px) {
        width: 61px;
      }
    }
    .grey-ribbon {
      font-size: 1.4rem;
      font-weight: 900;
      @media screen and (max-width: 480px) {
        font-size: 1rem;
      }
      display: flex;
      flex: 1;
      position: relative;

      .left,
      .right,
      .center {
        text-align: center;
        border: 0px solid rgba(#07181d, 0.1);
        border-radius: 0.4rem;
        background-color: rgba(#07181d, 0.1);
      }
      .left {
        padding: 0.73rem 0.4rem;
        flex: 1;
        text-align: center;
        @media screen and (max-width: 480px) {
          // padding: 0.26rem;
          // padding: 0;
          padding: 0.2rem 0.4rem;
        }
      }
      .center {
        // width: 35px;
        // padding: 0.53rem 1.76rem;
        // margin-right: 0.3rem;
        // margin-left: 0.3rem;
        width: 35px;
        padding: 0.73rem 0.5rem;
        margin-right: 0.3rem;
        margin-left: 0.3rem;
        text-align: center;
        flex-shrink: 0;
        @media screen and (max-width: 480px) {
          // padding: 0.26rem 1.42rem;
          padding: 0.2rem 0.4rem;
        }
      }
      .right {
        padding: 0.73rem 0.4rem;
        flex: 1;
        text-align: center;
        @media screen and (max-width: 480px) {
          // padding: 0.26rem;
          // padding: 0;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }

  .pass-content {
    margin-top: 1rem;
    .first-ribbon,
    .second-ribbon,
    .third-ribbon,
    .fourth-ribbon,
    .fifth-ribbon,
    .sixth-ribbon {
      display: flex;
      position: relative;
      gap: 1rem;
      align-items: center;
      margin-bottom: 2rem;

      @media screen and (max-width: 480px) {
        gap: 0.5rem;
      }

      .gap-between {
        gap: 0.2rem;
      }
      .left,
      .right,
      .center {
        color: $white;
        border: 0px solid rgba(#07181d, 0.1);
        border-radius: 0.4rem;
        background-color: rgba(#07181d, 0.1);
      }
      .left {
        padding: 1rem 0.2rem;
        flex: 1;
        text-align: center;
        @media screen and (max-width: 480px) {
          padding: 0.4rem 0.4rem;
          // padding: 0.4rem;
        }
      }
      .center {
        // padding: 0.8rem 1.3rem;
        // margin-right: 0.3rem;
        // margin-left: 0.3rem;
        width: 35px;
        padding: 1rem 0.5rem;
        margin-right: 0.3rem;
        margin-left: 0.3rem;
        text-align: center;
        flex-shrink: 0;
        @media screen and (max-width: 480px) {
          // padding: 0.4rem 1rem;
          padding: 0.4rem 0.4rem;
        }
      }
      .right {
        padding: 1rem 0.2rem;
        flex: 1;
        text-align: center;
        @media screen and (max-width: 480px) {
          // padding: 0.4rem;
          padding: 0.4rem 0.3rem;
        }
      }
      .first-ribbon-center {
        flex: 1;
        display: flex;
        font-size: 1rem;
        @media screen and (max-width: 480px) {
          font-size: 0.8rem;
        }
      }
      .pass-content-left-first {
        width: 40px;
        flex: 0 0 auto;
        font-size: 1.4rem;
        line-height: 1.2;
        @media screen and (max-width: 480px) {
          font-size: 1rem;
          width: 29px;
        }
      }

      .pass-content-right-first {
        width: 80px;
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.2;

        @media screen and (max-width: 480px) {
          font-size: 1rem;
          width: 63px;
        }
        align-items: center;

        .left-span {
          text-align: center;
          flex: 1;
        }

        .right-span {
          width: 35px;
          text-align: center;
          flex-shrink: 0;
          font-size: 1rem;
          color: $white;
          border: 0px solid rgba(#07181d, 0.1);
          border-radius: 0.4rem;
          background-color: rgba(#07181d, 0.1);
          padding: 1rem 0.5rem;
          @media screen and (max-width: 480px) {
            padding: 0.4rem 0.5rem;
            font-size: 0.8rem;
          }
        }
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // margin-bottom: 1.7rem;
      }
    }

    .last-ribbon {
      display: flex;
      justify-content: end;
      .pass-content-right-first {
        width: 80px;
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 480px) {
          font-size: 1rem;
          width: 63px;
        }
        align-items: center;

        .left-span {
          text-align: center;
          flex: 1;
        }

        .right-span {
          width: 35px;
          text-align: center;
          flex-shrink: 0;
          font-size: 1rem;
          border: 0px solid rgba(#07181d, 0.1);
          border-radius: 0.4rem;
          background-color: rgba(#07181d, 0.1);
          padding: 1rem 0.5rem;
          @media screen and (max-width: 480px) {
            padding: 0.4rem 0.5rem;
            font-size: 0.8rem;
          }
        }
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // margin-bottom: 1.7rem;
      }
    }

    .first-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #b72537;
        }
        .center {
          background-color: #99a335;
        }
        .right {
          background-color: #e7a702;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #ea7242;
        }
      }
    }
    .second-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #a85080;
        }
        .center {
          background-color: #5927d3;
        }
        .right {
          background-color: #05668d;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #59575d;
        }
      }
    }
    .third-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #e7a702;
        }
        .center {
          background-color: #507ba8;
        }
        .right {
          background-color: #ff6275;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #507ba8;
        }
      }
    }
    .fourth-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #a78959;
        }
        .center {
          background-color: #619b74;
        }
        .right {
          background-color: #8367c7;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #e7a702;
        }
      }
    }
    .fifth-ribbon {
      $colors: #ff6275, #a77c81, #507ba8, #95979b, #05668d, #619b74, #d863a2;
      $span-count: length($colors);
      .first-ribbon-center {
        @for $i from 1 through $span-count {
          span:nth-child(#{$i}) {
            background-color: nth($colors, $i);
          }
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #47b4a8;
        }
      }
    }
    .sixth-ribbon {
      .first-ribbon-center {
        span {
          color: #07181d;

          font-weight: 900;
        }
        // .left {
        //   background-color: #a78959;
        // }
        .center {
          background-color: #e6e8e8;
        }
        // .right {
        //   background-color: #8367c7;
        // }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #e7a702;
        }
      }
    }
    .last-ribbon {
      .pass-content-right-first {
        .right-span {
          background-color: #507ba8;
          color: $white;
        }
      }
    }

    // display: block;
  }
}

.first-card,
.second-card {
  .pass-content {
    .first-card-center {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.2rem;
      .first-card-center-left {
        flex: 1;
        .first-ribbon,
        .second-ribbon,
        .third-ribbon,
        .fourth-ribbon,
        .fifth-ribbon,
        .sixth-ribbon {
          display: flex;
          position: relative;
          gap: 1rem;
          align-items: center;
          margin-bottom: 2rem;

          @media screen and (max-width: 480px) {
            gap: 0.5rem;
          }

          .gap-between {
            gap: 0.2rem;
          }
          .left,
          .right,
          .center {
            color: $white;
            border: 0px solid rgba(#07181d, 0.1);
            border-radius: 0.4rem;
            background-color: rgba(#07181d, 0.1);
          }
          .left {
            padding: 1rem 0.2rem;
            flex: 1;
            text-align: center;
            @media screen and (max-width: 480px) {
              padding: 0.4rem 0.4rem;
              // padding: 0.4rem;
            }
          }
          .center {
            // padding: 0.8rem 1.3rem;
            // margin-right: 0.3rem;
            // margin-left: 0.3rem;
            width: 35px;
            padding: 1rem 0.5rem;
            margin-right: 0.3rem;
            margin-left: 0.3rem;
            text-align: center;
            flex-shrink: 0;
            @media screen and (max-width: 480px) {
              // padding: 0.4rem 1rem;
              padding: 0.4rem 0.4rem;
            }
          }
          .right {
            padding: 1rem 0.2rem;
            flex: 1;
            text-align: center;
            @media screen and (max-width: 480px) {
              // padding: 0.4rem;
              padding: 0.4rem 0.3rem;
            }
          }
          .first-ribbon-center {
            flex: 1;
            display: flex;
            font-size: 1rem;
            @media screen and (max-width: 480px) {
              font-size: 0.8rem;
            }
          }
          .pass-content-left-first {
            width: 40px;
            flex: 0 0 auto;
            font-size: 1.4rem;
            line-height: 1.2;
            @media screen and (max-width: 480px) {
              font-size: 1rem;
              width: 29px;
            }
          }

          .pass-content-right-first {
            width: 80px;
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            font-size: 1.4rem;
            line-height: 1.2;

            @media screen and (max-width: 480px) {
              font-size: 1rem;
              width: 63px;
            }
            align-items: center;

            .left-span {
              text-align: center;
              flex: 1;
            }

            .right-span {
              width: 35px;
              text-align: center;
              flex-shrink: 0;
              font-size: 1rem;
              color: $white;
              border: 0px solid rgba(#07181d, 0.1);
              border-radius: 0.4rem;
              background-color: rgba(#07181d, 0.1);
              padding: 1rem 0.5rem;
              @media screen and (max-width: 480px) {
                padding: 0.4rem 0.5rem;
                font-size: 0.8rem;
              }
            }
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // margin-bottom: 1.7rem;
          }
        }

        .last-ribbon {
          display: flex;
          justify-content: end;
          .pass-content-right-first {
            width: 80px;
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            font-size: 1.4rem;
            line-height: 1.2;
            margin-bottom: 1.5rem;
            @media screen and (max-width: 480px) {
              font-size: 1rem;
              width: 63px;
            }
            align-items: center;

            .left-span {
              text-align: center;
              flex: 1;
            }

            .right-span {
              width: 35px;
              text-align: center;
              flex-shrink: 0;
              font-size: 1rem;
              border: 0px solid rgba(#07181d, 0.1);
              border-radius: 0.4rem;
              background-color: rgba(#07181d, 0.1);
              padding: 1rem 0.5rem;
              @media screen and (max-width: 480px) {
                padding: 0.4rem 0.5rem;
                font-size: 0.8rem;
              }
            }
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // margin-bottom: 1.7rem;
          }
        }
      }

      .first-card-center-right {
        display: grid;
        gap: 1.2rem;
        margin-top: -0.5rem;
        .pass-content-right-first {
          width: 80px;
          flex: 0 0 auto;
          display: flex;
          justify-content: space-between;
          font-size: 1.4rem;
          line-height: 1.2;

          @media screen and (max-width: 480px) {
            font-size: 1rem;
            width: 63px;
          }
          align-items: center;

          .left-span {
            text-align: center;
            flex: 1;
          }

          .right-span {
            width: 35px;
            text-align: center;
            flex-shrink: 0;
            font-size: 1rem;
            color: $white;
            border: 0px solid rgba(#07181d, 0.1);
            border-radius: 0.4rem;
            background-color: rgba(#07181d, 0.1);
            padding: 1rem 0.5rem;
            @media screen and (max-width: 480px) {
              padding: 0.4rem 0.5rem;
              font-size: 0.8rem;
            }
          }
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          // margin-bottom: 1.7rem;
        }
      }
    }
  }
}

// .first-card,
// .second-card {
//   .pass-content {
//     .second-ribbon,
//     .third-ribbon,
//     .fourth-ribbon,
//     .last-ribbon {
//       .pass-content-right-first {
//         // min-height: 53px;
//         margin-top: -2rem;
//       }
//     }
//   }
// }

// .first-card,
// .second-card {
//   .pass-content {
//     .second-ribbon,
//     .third-ribbon,
//     .fourth-ribbon,
//     .last-ribbon {
//       .pass-content-left-first {
//         margin-top: 1.5rem;
//       }
//       .first-ribbon-center {
//         margin-top: 1.5rem;
//       }
//       .pass-content-right-first {
//       }
//     }
//   }
// }

// .first-card,
// .second-card {
//   .pass-content {
//     .fifth-ribbon {
//       .pass-content-left-first {
//         margin-top: 0.5rem;
//       }
//       .first-ribbon-center {
//         margin-top: 0.5rem;
//       }
//       .pass-content-right-first {
//         margin-top: 0.5rem;
//       }
//     }
//   }
// }

.first-card,
.second-card {
  .pass-content {
    .fifth-ribbon {
      margin-bottom: 0.5rem;
    }
  }
}

.second-card {
  .pass-content {
    .first-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #958082;
        }
        .center {
          background-color: #619b74;
        }
        .right {
          background-color: #8d7944;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #e7a702;
        }
      }
    }
    .second-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #507ba8;
        }
        .center {
          background-color: #7a5dc1;
        }
        .right {
          background-color: #95979b;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #f78154;
        }
      }
    }
    .third-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #d863a2;
        }
        .center {
          background-color: #507ba8;
        }
        .right {
          background-color: #e09143;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #a85080;
        }
      }
    }
    .fourth-ribbon {
      .first-ribbon-center {
        .left {
          background-color: #865303;
        }
        .center {
          background-color: #bd505d;
        }
        .right {
          background-color: #653fc2;
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #a89f68;
        }
      }
    }
    .fifth-ribbon {
      $colors: #619b74, #f78154, #508ba9, #a89f68, #bc4749, #e6ac73, #a89f68;
      $span-count: length($colors);
      .first-ribbon-center {
        @for $i from 1 through $span-count {
          span:nth-child(#{$i}) {
            background-color: nth($colors, $i);
          }
        }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #e6ac73;
        }
      }
    }
    .sixth-ribbon {
      .first-ribbon-center {
        span {
          color: #07181d;
        }
        // .left {
        //   background-color: #a78959;
        // }
        .center {
          background-color: #e6e8e8;
        }
        // .right {
        //   background-color: #8367c7;
        // }
      }
      .pass-content-right-first {
        .right-span {
          background-color: #e7a702;
        }
      }
    }
    .last-ribbon {
      .pass-content-right-first {
        .right-span {
          background-color: #bc4749;
          color: $white;
        }
      }
    }
  }
}

.third-card {
  background-color: $white;
  border-radius: 2rem;
  box-shadow: 0px 5px 20px 5px #0000000d;
  padding: 1rem 1rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 0rem;
  position: relative;
  color: $black;
  .first-card-info-icon {
    .info-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
  }

  // .first-card-info-icon.active {
  //   &::after {
  //     content: "";
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background: #74666630;
  //     z-index: 2;
  //     backdrop-filter: blur(15px) brightness(30%);
  //   }
  //   .info-icon {
  //     z-index: 3;
  //   }
  // }

  .first-ribbon {
    display: flex;
    align-items: start;

    .left {
      width: 55px;
      font-size: 1.4rem;
      @media screen and (max-width: 480px) {
        font-size: 1rem;
        width: 42px;
      }
    }

    .right {
      flex: 1;
      display: block;
      .colorful-distribution {
        margin: 0rem;
        span {
          flex: 1;
        }
      }

      .colorful-distribution-numbers {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        span {
          color: $black;
        }
      }
    }
  }
}

.border-radius-10 {
  border-radius: 5rem;
}

.info-icon {
}
.popover-wrap {
  &.active {
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: #74666630;
      background: #7b757530;
      z-index: 2;
      backdrop-filter: blur(15px) brightness(30%);
    }
    .info-icon {
      z-index: 3;
    }
  }
}

.first-card,
.second-card,
.third-card {
  .custom-popover {
    top: 24px;
    right: 24px;
  }
}

.custom-popover {
  position: absolute;
  width: 350px;
  background-color: #ffffff;
  border: 0px;
  border-radius: 3rem;
  padding: 2rem;
  z-index: 3;
  cursor: pointer;

  .close-popover {
    height: 3rem;
    width: 3rem;
    background-color: #07181d;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    margin: auto;
    border: solid 2px #fff;
  }
}

.custom-popover p {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.68rem;
  text-align: justify;
}

.detail-width {
  width: 100px;
  margin-top: -1rem;
}

.game-detail-score {
  font-size: 2.5rem;
  font-weight: 600;
}

.football {
  // margin-top: 0.5rem;
}

.game-badge {
  color: $white;
  font-weight: normal;
  border-radius: 10rem;
  line-height: 1;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  position: absolute;
  right: 0;
  top: -50%;
  transform: translateY(50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &.free {
    background-color: $brown;
  }

  &.purchased {
    background-color: $green;
  }
}
