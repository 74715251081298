/* Outlined Box Below Header */
.header-wrapper {
  margin: 6px 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  flex-wrap: wrap;
  /* padding: 20px 10%; */
  position: relative; /* For the dropdown to be positioned correctly */
}

.logo img {
  max-height: 55px;
}

.nav-menu {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
}

.nav-menu a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.nav-menu a:hover {
  color: #ff6600;
}

.language-dropdown {
  background-color: #fff;
  font-size: 16px;
  /* margin: 35px 0px; */
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 600;
  color: #333;
}

.header-play,
.header-apple {
  max-height: 40px;
  cursor: pointer;
}

/* Hamburger menu button for mobile */
.hamburger-menu {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

/* Mobile menu styles */
.nav-menu.mobile-open {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  left: 15%;
  width: 70%;
  background-color: white; /* Dropdown background color */
  padding: 15px 10px;
  z-index: 1000;
}

.mobile-menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Media Query for devices with max-width of 768px */
@media (max-width: 768px) {
  .header {
    padding-bottom: 5px;
  }
  .hamburger-menu {
    display: block; /* Show the hamburger menu button */
  }

  .nav-menu {
    display: none; /* Hide the menu initially */
  }

  .logo {
    padding-left: 0px;
  }

  .language-dropdown {
    font-size: 14px;
  }

  .header-play,
  .header-apple {
    max-height: 30px;
    margin: 3px 10px;
  }
}

/* Media Query for devices with max-width of 480px */
@media (max-width: 480px) {
  .header {
    padding-bottom: 5px;
  }

  .logo img {
    max-height: 40px;
  }

  .nav-menu.mobile-open {
    align-items: center;
  }

  .nav-menu a {
    margin: 5px 0;
    font-size: 14px;
  }

  .language-dropdown {
    font-size: 14px;
  }

  .header-play,
  .header-apple {
    max-height: 20px;
    margin: 5px 1px;
  }
}

/* Media Query for devices with max-width of 320px */
@media (max-width: 320px) {
  .header {
    /* padding: 10px 3%; */
  }

  .logo img {
    max-height: 35px;
  }

  .nav-menu.mobile-open {
    align-items: center;
    justify-content: space-around;
  }

  .nav-menu a {
    margin: 3px 0;
    font-size: 12px;
  }

  .language-dropdown {
    font-size: 12px;
  }

  .header-play,
  .header-apple {
    max-height: 15px;
  }
}
.services-link {
  position: relative;
  display: inline-block;
  width: 200px; /* Adjust width based on your layout */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.services-link:hover {
  width: auto; /* Allows full text to be displayed */
  overflow: visible;
  white-space: normal; /* Wrap text when hovered */
  background: #fff; /* Optional: Add background for better visibility */
  z-index: 10;
  padding: 0 5px; /* Optional: Add padding for better spacing */
  border: 1px solid #ccc; /* Optional: Add border for emphasis */
}

.header-outlined-box {
  margin: 0px auto; /* Center the box below the header */
  padding: 0px 10px;
  border: 2px solid #355935; /* Orange outline */
  border-radius: 27px;
  background-color: #355935; /* Background color */
  max-width: 735px; /* Limit the width */
  text-align: center; /* Center the text */
  font-size: 18px;
  font-weight: 700;
  font-weight: 600;
  line-height: 35px;
  color: white;
  z-index: 1; /* Ensure it stays above other elements */
}

/* Responsive Adjustments for the Outlined Box */
@media (max-width: 768px) {
  .header-outlined-box {
    font-size: 14px;
    padding: 0px 15px;
    max-width: 100%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .header-outlined-box {
    font-size: 8px;
    line-height: 1;
    padding: 5px 10px;
  }
}
