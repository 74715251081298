.btn {
  &.btn-primary {
    color: $white;
    
    
    &:active {
      color: $white;
      
    }
  }

  &.btn-outline-primary {

    &:hover,
    &:active {
      background-color: transparent;
      color: $primary;
    }
  }

  &.btn-size-xs {
    min-width: 10rem;
  }

  &.btn-size-sm {
    min-width: 14rem;
  }

  &.btn-breadcrumb {
    padding: 0;
    border: 0;
    line-height: 1.4;
    display: block;
  }
}