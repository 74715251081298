.termContainer{
  padding: 0px 50px 50px 50px;
}
.termHeading {
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  text-align: left;
  padding-bottom: 20px;
}
.termBottomTextHeading {
  color: var(--Gray-Body-Color, #505050);

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  text-transform: capitalize;
}

.termBottomText {
  color: var(--Gray-Body-Color, #505050);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: capitalize;
}

.termsHeading {
  color: var(--Connect-button-color, #242020);

  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 108.333% */
  text-transform: capitalize;
}

.termsText {
  color: var(--Gray-Body-Color, #505050);
  /* leading-trim: both;
text-edge: cap; */
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  text-transform: capitalize;
  /* margin-bottom: 0px; */
}

.termsEmailStyle {
  color: var(--Orange, #f37b2f);

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  text-transform: capitalize;
}


@media (max-width: 768px) {
  .termContainer{
    padding: 0px 20px;
  }
}