@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

/* In your global CSS file (e.g., index.css or App.css) */
body {
  font-family: "Poppins", sans-serif;
}

/* body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
} */

/* .hero-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
} */

/* .hero-content {
  max-width: 600px;
  margin: auto;
} */

/* .hero-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.how-it-works,
.value-section,
.about-section,
.download-section {
  padding: 40px 20px;
  text-align: center;
} */

/* .steps,
.values,
.about-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
} */

/* .footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
} */

/* @media (min-width: 768px) {
  .hero-section {
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  .steps,
  .values,
  .about-content {
    flex-direction: row;
    justify-content: space-around;
  }

  .about-content {
    gap: 40px;
  }
} */

.App {
  max-width: 1220px;
  width: 100%;
  padding: 0 12px;
  margin: 0 auto;
}
