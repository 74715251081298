/* .value-section {
  margin-top: -95px;
  text-align: center;
}

.value-section h2 {
  font-size: 2.2rem;
  margin-bottom: 40px;
  color: #333;
}

.values {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0 auto;
}

.value {
  max-width: 400px;
  margin-bottom: 20px;
  border: 2px solid #f37b2f;
  border-radius: 34px;
  padding: 30px;
} */

.value-section {
  text-align: center;
  padding: 20px;
}

.value-section h2 {
  font-size: 45px;
  margin-bottom: 40px;
}

.value-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.value-card {
  border: 3px solid #ddd;
  border-radius: 34px;
  padding: 30px;
  max-width: 530px;
  text-align: left;
}

.value-card p {
  color: #505050;
  line-height: 28px;
  font-size: 16px;
}

.value-card-1 {
  border-color: #f37b2f; /* Orange color */
}

.value-card-2 {
  border-color: #355935; /* Green color */
}

.value-card h3 {
  font-size: 50px;
  color: #f37b2f; /* Orange for the first card */
  margin-bottom: 10px;
}

.value-card-2 h3 {
  color: #355935; /* Green for the second card */
}

.value-card h4 {
  font-size: 35px;
  margin-bottom: 20px;
}

/* .value-card p {
  font-size: 14px;
  line-height: 1.6;
} */

@media (max-width: 390px) {
  .value-section h2 {
    font-size: 1.2rem;
  }
  .value-card h4 {
    font-size: 1.5rem;
  }
}
