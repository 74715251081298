select {


  &.select-dark {
    background-image: url("../images/select-arrow.svg");
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding-right: 36px;
    filter: invert(1);
    border-radius: 2rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  &.select-light {
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding-right: 36px;
    border-radius: 2rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}

.input-item {
  position: relative;
  margin-bottom: 1.5rem;

  label {
    color: #505050;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }

  .form-control {
    background-color: #f9fafb;
    border-radius: 3.2rem;
    color: #07181d;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 1.2rem 2rem;
    box-shadow: none;
    padding-right: 44px;
    padding-left: 44px;
  }

  .input-icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
    font-size: 1.8rem;
    color: #9da4ae;
  }

  .input-lock-icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
    font-size: 1.8rem;
    color: #9da4ae;
  }

  .input-mail-icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
    font-size: 1.8rem;
    color: #9da4ae;
  }
}

.forgot-password {
  display: flex;
  justify-content: end;
  font-weight: 600;
}

.submit-btn {
  width: 100%;
  border-radius: 3.2rem;
  padding: 1rem 2rem;
}

.reset-password-link {
  font-weight: 500;
  color: #111927;
}

.email-reset {
  font-weight: 600;
  color: #111927;
}

.games-search {
  background-color: #f9fafb;
  border-radius: 3.2rem;
  color: #07181d;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0.8rem 2rem;
  box-shadow: none;
  padding-left: 44px;
}

.input-search-icon {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
  font-size: 1.8rem;
  color: #9da4ae;
}