@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?eqdeod");
  src: url("../fonts/icomoon.eot?eqdeod#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?eqdeod") format("truetype"),
    url("../fonts/icomoon.woff?eqdeod") format("woff"),
    url("../fonts/icomoon.svg?eqdeod#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  lock: "\e900",
  sms: "\e901",
  eye-slash: "\e902",
  eye: "\e903",
  search-1: "\e904",
  arrow-down: "\e905",
);

@each $name, $icon in $icons {
  .icon-#{$name}::before {
    content: $icon;
  }
}
